import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'gatsby-plugin-intl'
import favicon from '../assets/icons/favicon.png'

// ================================================================================================

const SEO = ({ description, intl, title }) => {
  const metaDescription = description || 'Simple Darts Scoreboard App for iOS and Android'

  return (
    <Helmet
      htmlAttributes={{ lang: intl.locale }}
      title={title}
      // titleTemplate={`Darts Scorer 180`}
      titleTemplate={`%s | Darts Scorer 180`}
      meta={[
        {
          property: `title`,
          content: title
        },
        {
          property: `og:title`,
          content: title
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:card`,
          content: `summary`
        }
      ]}
      link={[{ rel: 'icon', type: 'image/png', href: `${favicon}` }]}
    />
  )
}

// ================================================================================================

export default injectIntl(SEO)
