import React from 'react'
import { createGlobalStyle } from 'styled-components'

import 'typeface-montserrat'
import 'typeface-open-sans'

// ================================================================================================

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    {children}
  </>
)

// ================================================================================================

const GlobalStyles = createGlobalStyle`
  :root {
    --bg-color: #f2f2f2;
    --footer-height: 4rem;
    --header-height: 3rem;
    --text-color: #111;
    --text-color-invert: #fdfdfd;
    --text-color-light: #999;
    --text-color-lighter: #ccc;
    --primary-color: #3DC9AA;
    --secondary-color: #2E3E4F;
  }

  /* CSS Reset */

  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  /* Basic styles */

  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--bg-color);
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-color);
  }

  /* Make images easier to work with */
  img {
    display: block;
    max-width: 100%;
  }

  p {
    line-height: 1.4;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 800;
    line-height: 1.1;
  }

  a, button {
    transition: .25s;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      scroll-behavior: auto !important;
      transition-duration: 0.01ms !important;
    }
  }
`

// ================================================================================================

export default Layout
