import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'

// ================================================================================================

const Footer = ({ intl }) => {
  return (
    <FooterWrapper>
      <FooterItemExternal
        href="https://www.appitized.io/imprint"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="footer.legal" />
      </FooterItemExternal>
      <FooterItemExternal
        href={intl.formatMessage({ id: 'links.privacy' })}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="footer.privacy" />
      </FooterItemExternal>
      <FooterItemExternal
        href={intl.formatMessage({ id: 'links.terms' })}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="footer.terms" />
      </FooterItemExternal>
      <FooterItemExternal
        href="mailto:info@dartsscorer.app"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="footer.contact" />
      </FooterItemExternal>
      <FooterItemExternal
        href="https://www.appitized.io"
        rel="noopener noreferrer"
        target="_blank"
      >
        &copy; {`${new Date().getFullYear()} Appitized GmbH`}
      </FooterItemExternal>
    </FooterWrapper>
  )
}

// ================================================================================================

const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    padding: 0;
    height: var(--footer-height);
  }
`

const FooterItem = styled(Link)`
  font-size: 1rem;
  color: var(--text-color-light);
  text-decoration: none;
  margin: 0 0 1rem 0;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 600px) {
    margin: 0 1rem;
  }
`

const FooterItemExternal = styled.a`
  font-size: 1rem;
  color: var(--text-color-light);
  text-decoration: none;
  margin: 0 0 1rem 0;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 600px) {
    margin: 0 1rem;
  }
`

// ================================================================================================

export default injectIntl(Footer)
